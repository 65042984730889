<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>Отчёты для educont</subpage-top-bar>

        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="!errors"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col :cols="12" class="fill-height overflow-auto">
                    <div class="d-flex flex-row justify-start align-center">
                        <!-- From -->
                        <div class="mr-2">
                            <v-datetime-picker 
                                label="От" 
                                v-model="filter.from"
                                :timePickerProps="{
                                    format: '24hr'
                                }"
                                okText="Выбрать"
                                clearText="Очистить"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </div>
                        <div class="mr-2">
                            <!-- To -->
                            <v-datetime-picker 
                                label="До" 
                                v-model="filter.to"
                                :timePickerProps="{
                                    format: '24hr'
                                }"
                                okText="Выбрать"
                                clearText="Очистить"
                            >
                                <template v-slot:dateIcon>
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template v-slot:timeIcon>
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </div>

                        <div class="mr-2">
                            <v-select
                                v-model="filter.role"
                                :items="roles"
                                label="Роль"
                            />
                        </div>

                        <div class="mr-2">
                            <v-checkbox
                                v-model="filter.groupResults"
                                label="Группировать по ЦОК и по пользователям"
                                hide-details
                                class="mt-1"
                            ></v-checkbox>
                        </div>
                    </div>

                    <div class="d-flex flex-row justify-start align-center">

                        <div class="mr-2">
                            <v-btn 
                                dark 
                                small
                                color="purple darken-3" 
                                :loading="loading"
                                :disabled="!isFilterFormFullfilled"
                                @click.prevent.stop="fetchHandler"
                            >
                                <v-icon left>mdi-filter</v-icon>
                                Фильтр
                            </v-btn>
                            <v-btn 
                                dark 
                                small
                                color="orange darken-3" 
                                class="ml-2"
                                @click.prevent.stop="reset"
                            >
                                <v-icon left>mdi-refresh</v-icon>
                                Сброс
                            </v-btn>

                            <v-btn 
                                dark 
                                small
                                color="orange darken-3" 
                                class="ml-2"
                                :disabled="!items.length"
                                @click.prevent.stop="exportToCsv"
                            >
                                <v-icon left class="mx-auto">mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <v-divider class="mt-10"/>

                    <v-simple-table v-if="items && items.length">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    №
                                </th>
                                <th class="text-left">
                                    ЦОК
                                </th>
                                <th
                                    v-if="items && items[0] && items[0].profileId"
                                    class="text-left"
                                >
                                    Пользователь
                                </th>
                                <th
                                    v-if="items && items[0] && Number.isInteger(items[0].value)"
                                    class="text-left"
                                >
                                    Дней визитов
                                </th>

                                <th
                                    v-if="items && items[0] && Number.isInteger(items[0].twoAndLessValue)"
                                    class="text-left"
                                >
                                    Воспользовались лицензией 2 и менее раз
                                </th>

                                <th
                                    v-if="items && items[0] && Number.isInteger(items[0].threeAndMoreValue)"
                                    class="text-left"
                                >
                                    Воспользовались лицензией 3 и более раз
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="totals" class="selected-row">
                                <td></td>
                                <td>Итого</td>
                                <td v-if="Number.isInteger(items[0].value)"></td>
                                <td v-if="Number.isInteger(items[0].value)">{{ totals.value }}</td>
                                <td v-if="Number.isInteger(items[0].twoAndLessValue)">{{ totals.twoAndLessValue }}</td>
                                <td v-if="Number.isInteger(items[0].threeAndMoreValue)">{{ totals.threeAndMoreValue }}</td>
                            </tr>
                            <tr
                                v-for="(item, index) in items"
                                :key="index"
                            >
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.title }}</td>
                                <td v-if="item.profileId">
                                    {{ item.profileId }}
                                </td>
                                <td v-if="Number.isInteger(items[0].value)">{{ item.value }}</td>
                                <td v-if="Number.isInteger(items[0].twoAndLessValue)">{{ item.twoAndLessValue }}</td>
                                <td v-if="Number.isInteger(items[0].threeAndMoreValue)">{{ item.threeAndMoreValue }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <div v-else class="mx-auto">
                <v-alert type="error">
                    {{ errors || 'Произошла ошибка на сервере. Обратитесь за помощью к администратору.' }}
                </v-alert>

                <div
                    v-if="allowEdit"
                    class="w-100 d-flex justify-center align-center"
                >
                    <v-btn class="mx-auto" @click="resetError">
                        <v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
                        Отмена
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import SubpageTopBar from '@/components/template/SubpageTopBar';
import arrHelper from '@/helpers/ArrayHelper'

const todayYMD = moment().format('YYYY-MM-DD');
const todayFrom = `${todayYMD} 00:00`;
const todayTo = `${todayYMD} 23:59`;

export default {
    name: "Stats",
    components: {
        SubpageTopBar
    },
    data () {
        return {
            errors: null,
            ready: false,
            loading: false,
            items: [],
            filter: null
        }
    },
    computed: {
        ...mapGetters("user", ["allowEdit"]),
        totalItemsValue () {
            if (!this.items.length) { return 0 }
            return this.items.map(item => parseInt(item.value)).reduce((i, p) => i + p)
        },
        isFilterFormFullfilled () {
            return this.filter.role !== null
        },
        eduCoursesShort () {
            return this.$store.state.app.eduCoursesShort || []
        },
        roles () {
            return [
                {
                    id: 'student',
                    value: ['=', 'profileRole', 'student'],
                    text: 'Ученик'
                },
                {
                    id: 'teacher',
                    value: ['=', 'profileRole', 'teacher'],
                    text: 'Учитель'
                }
            ]
        },
        filterFetchTypes () {
            return [
                {
                    text: 'Все',
                    value: []
                },
                {
                    text: 'Воспользовались лицензией 2 и менее раз',
                    value: ['<', 'visiting_days', 3]
                },
                {
                    text: 'Воспользовались лицензией 3 и более раз',
                    value: ['>', 'visiting_days', 2]
                }
            ]
        },
        totals () {
            if (!this.items?.length) { return null }

            if (Number.isInteger(this.items[0].value)) {
                return {
                    value: this.items.reduce((prev, current) => {
                        return prev + current.value
                    }, 0)
                }
            } else if (Number.isInteger(this.items[0].twoAndLessValue)) {
                return {
                    twoAndLessValue: this.items.reduce((prev, current) => {
                        return prev + current.twoAndLessValue
                    }, 0),
                    threeAndMoreValue: this.items.reduce((prev, current) => {
                        return prev + current.threeAndMoreValue
                    }, 0)
                }
            }
            return null
        }
    },
    methods: {
        async fetch (fetchTypeQuery = this.filterFetchTypes[0].value) {
            const { data, error } = await this.$store.dispatch('stats/licenseList', {
                filter: JSON.stringify([
                    'AND',
                    ['>=', 'created_at', moment(this.filter.from).format('X')],
                    ['<=', 'created_at', moment(this.filter.to).format('X')],
                    fetchTypeQuery,
                    this.filter.role
                ])
            });
            if (error) { throw new Error(error) }

            return data.items
        },
        // Reset filter
        reset () {
            this.$set(this, 'filter', {
                from: todayFrom,
                to: todayTo,
                role: this.roles.find(role => role.id === 'teacher')?.value,
                groupResults: true
            });
            this.items = [];
        },
         async fetchHandler () {
            this.loading = true;
            this.errors = null;
            try {
                // Ветка обработки запроса на создание таблицы с группировкой
                if (this.filter.groupResults) {
                    let twoAndLessResult = await this.fetch(this.filterFetchTypes[1].value)
                    let threeAndMoreResult = await this.fetch(this.filterFetchTypes[2].value)

                    // TODO: проверить здесь на пустой результат (такой запрос, который вернет 0 строк)

                    twoAndLessResult = this.groupBy(twoAndLessResult, 'externalId')
                    threeAndMoreResult = this.groupBy(threeAndMoreResult, 'externalId')

                    const result = {}

                    Object.keys(twoAndLessResult).map(key => {
                        result[key] = {
                            title: this.eduCoursesShort?.find(course => course?.externalId === key)?.courseName || `externalId: ${key}`,
                            twoAndLessValue: twoAndLessResult[key].length,
                            threeAndMoreValue: 0
                        }
                    })

                    Object.keys(threeAndMoreResult).map(key => {
                        if (!result[key]) {
                            result[key] = {
                                title: this.eduCoursesShort?.find(course => course?.externalId === key)?.courseName || `externalId: ${key}`,
                                twoAndLessValue: 0,
                                threeAndMoreValue: threeAndMoreResult[key].length
                            }
                        } else {
                            result[key].threeAndMoreValue = threeAndMoreResult[key].length
                        }
                    })

                    this.items = Object
                                    .keys(result)
                                    .map(key => result[key])
                                    .sort(
                                        arrHelper.compare({
                                            threeAndMoreValue: 1,
                                            twoAndLessValue: 1
                                        })
                                    )
                    
                // Ветка обработки запроса на создание таблицы без группировки
                } else {
                    let result = await this.fetch(this.filterFetchTypes[0].value)

                    this.items = result
                                    .map(item => {
                                        return {
                                            title: this.eduCoursesShort?.find(course => course?.externalId === item.externalId)?.courseName || `externalId: ${item.externalId}`,
                                            value: parseInt(item.visiting_days),
                                            profileId: item.profileId
                                        }
                                    })
                                    .sort((a, b) => b.value - a.value)
                }
                
            } catch (e) {
                this.errors = e;
                console.error(e);
            }
            this.loading = false;
        },
        groupBy (xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        resetError () {
            this.errors = null;
        },
        async exportToCsv () {
            try {
                await this.$store.dispatch('app/toggleLoading', true);

                const separator = prompt('Укажите символ разделитель для столбцов.', ';');

                const csv = this.buildCSVStructure(separator)

                let fileName = "Посещения платформы.csv";
                let file = new File(["\uFEFF" + csv], fileName, {
                    type: "text/csv;charset=utf-8",
                });
                let exportUrl = URL.createObjectURL(file);
                window.location.assign(exportUrl);
                URL.revokeObjectURL(exportUrl);
            } catch (e) {
                console.error(e);
            } finally {
                this.$store.dispatch('app/toggleLoading', false);
            }
        },
        buildCSVStructure (separator = ';') {
            if (!this.items?.length) { throw new Error('Can\'t build CSV file without data.') }

            const grouped = Number.isInteger(this.items[0].twoAndLessValue)
            
            if (!grouped) {
                const rows = (this.items || []).map((item, index) => {
                    return _.join(
                        [
                            index + 1,
                            item.title,
                            item.profileId || '-',
                            item.value
                        ],
                        separator
                    );
                });
                return _.join(
                        [
                            _.join(
                                [
                                    "№",
                                    "ЦОК",
                                    "Пользователь",
                                    "Дней визитов"
                                ],
                                separator
                            ),
                            ...rows,
                        ],
                        "\r\n"
                    );
            } else {
                const rows = (this.items || []).map((item, index) => {
                    return _.join(
                        [
                            index + 1,
                            item.title,
                            item.twoAndLessValue,
                            item.threeAndMoreValue
                        ],
                        separator
                    );
                });
                return _.join(
                        [
                            _.join(
                                [
                                    "№",
                                    "ЦОК",
                                    "Воспользовались лицензией 2 и менее раз",
                                    "Воспользовались лицензией 3 и более раз"
                                ],
                                separator
                            ),
                            ...rows,
                        ],
                        "\r\n"
                    );
            }
            
        }
    },
    async created () {
        try {
            if (!this.allowEdit) {
                throw new Error('У вас недостаточно прав для доступа к этому разделу.');
            }
            // Fetch data 
            await this.$store.dispatch('app/fetchFast', {
                filter: ['AND', ['=', 'courseTypeId', 0]],
                fields: 'id,externalId,courseName'
            });
            this.reset();

        } catch (e) {
            console.error(e);
            this.errors = e.message;
        } finally {
            this.ready = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.td-errors {
    max-width: 250px;
    white-space: nowrap;
    overflow-x: auto;
}
tr.selected-row {
    background-color: #e7e5e5;
    font-weight: bold;
}
</style>